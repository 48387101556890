
let uid = 0;
export default class Slider {

    constructor({
            transitionDuration = 1000,
            sliderContainer = null,
            sliderSelector = '.sr-slider',
            clipSelector = '',
            autoplay = true,
            autoplayDuration = 3000,
            videoDuration = null,
            disableOnInteraction = true,
            keyboardControl = true,
            hoverEffect = true,
            hoverEffectThreshold = 15,
            disableTouch = false,
            loop = true,
            randomAnimationClasses = [],
            alwaysAnimate = false,
            progressMultiple = false,
            autoHeight = false,
            paginationType = 'fraction',
            on = {}
        }) {
        this.uid = uid;
        uid++;
        this.sliderContainer = sliderContainer;
        this.transitionDuration = transitionDuration;
        this.sliderEl = this.sliderContainer.querySelector(sliderSelector);
        if (clipSelector !== '') {
            this.clipEl = document.querySelector(clipSelector);
        }
        this.autoplay = autoplay;
        this.autoplayDuration = autoplayDuration;
        this.videoDuration = videoDuration;
        this.disableOnInteraction = disableOnInteraction;
        this.keyboardControl = keyboardControl;
        this.hoverEffect = hoverEffect;
        this.hoverEffectThreshold = hoverEffectThreshold;
        this.disableTouch = disableTouch;
        this.loop = loop;
        this.randomAnimationClasses = randomAnimationClasses;
        this.alwaysAnimate = alwaysAnimate;
        this.progressMultiple = progressMultiple;
        this.autoHeight = autoHeight;
        this.paginationType = paginationType;
        this.events = on;
    }

    slides = [];
    activeIndex = 0;
    animating = false;
    paginationCurrentEl = null;
    paginationBullets = [];
    progressEl = null;
    progressInnerEl = [];
    progressInitialized = false;
    autoplayTimeout = [];
    transitionTimeout = [];
    currentVideo = null;
    controlEl = null;
    btnNext = null;
    btnPrev = null;
    btnStart = null;
    btnStop = null;
    shouldAnimate = null;


    init = () => {
        this.shouldAnimate = false;

        let slides = this.sliderContainer.querySelectorAll('.sr-slider > li');
        slides.forEach((slide) => {
            this.slides.push(slide);
        });

        if (this.slides.length > 1  || this.alwaysAnimate === true) {
            this.shouldAnimate = true;
        } else {
            this.sliderContainer.classList.add('no-controls');
        }

        let pagination = this.sliderContainer.querySelector('.sr-slider-pagination');
        if (pagination && this.shouldAnimate) {
            this.initPagination(pagination);
        }

        this.progressEl = this.sliderContainer.querySelector('.sr-slider-progress');
        if (this.progressEl && this.shouldAnimate) {
            this.initProgress();
        }

        this.controlEl = this.sliderContainer.querySelector('.sr-slider-control');
        if (this.controlEl) {
            this.bindControl(this.controlEl);
        }

        let navigation = this.sliderContainer.querySelector('.sr-slider-navigation');
        if (navigation && this.shouldAnimate) {
            this.bindNavigation(navigation);
        }

        if (this.keyboardControl) {
            this.bindKeyboard();
        }

        if(!this.disableTouch) {
            this.initTouch();
        }

        this.setSlideClasses();
        this.setActive(0, true);

        if (this.hoverEffect) {
            this.initHoverEffect();
        }

        this.sliderEl.classList.add('sr-slider-initialized');

        if (typeof this.events['init'] === 'function') {
            this.events['init'](this);
        }
    }

    initTouch = () => {

        let touchstartX = 0;
        let touchendX = 0;

        const touchZone = this.sliderEl;

        touchZone.addEventListener('touchstart', evt => {
            touchstartX = evt.changedTouches[0].screenX;
        }, false);
        touchZone.addEventListener('mousedown', evt => {
            touchstartX = evt.screenX;
        }, false);

        touchZone.addEventListener('touchend', evt => {
            touchendX = evt.changedTouches[0].screenX;
            this.handleTouch(touchstartX, touchendX);
        }, false);

        touchZone.addEventListener('mouseup', evt => {
            touchendX = evt.screenX;
            this.handleTouch(touchstartX, touchendX);
        }, false);
    }

    handleTouch = (touchstartX, touchendX) => {
        if (touchendX < touchstartX) {
            this.setActive('next');
        }
        if (touchendX > touchstartX) {
            this.setActive('prev');
        }
    }

    bindNavigation = (navEl) => {
        this.btnNext = navEl.querySelector('.sr-slider-next');
        this.btnPrev = navEl.querySelector('.sr-slider-prev');

        if (typeof (this.btnNext) != 'undefined' && this.btnNext != null) {
            this.btnNext.addEventListener('click', evt => {
                evt.preventDefault();
                if(this.loop === false && this.activeIndex === this.slides.length - 1) {
                    this.autoplay = false;
                    return;
                }
                this.setActive('next');

                if(this.disableOnInteraction && this.btnStart != null && this.btnStop != null) {
                    this.autoplay = false;
                    this.btnStart.style.display = null;
                    this.btnStop.style.display = 'none';
                }
                this.resetCurrentVideo();
            });
        }
        if (typeof (this.btnPrev) != 'undefined' && this.btnPrev != null) {
            this.btnPrev.addEventListener('click', evt => {
                evt.preventDefault();
                if(this.loop === false && this.activeIndex === 0) {
                    this.autoplay = false;
                    return;
                }

                this.setActive('prev');

                if(this.disableOnInteraction && this.btnStart != null && this.btnStop != null) {
                    this.autoplay = false;
                    this.btnStart.style.display = null;
                    this.btnStop.style.display = 'none';
                }
                this.resetCurrentVideo();
            });
        }
    }

    bindKeyboard = () => {
        document.addEventListener('keydown', this.handleKeyboard);
    }

    handleKeyboard = (evt) => {
        if (this.sliderContainer.querySelectorAll(':hover').length) {

            if (evt.keyCode === 39) {
                // Right Arrow
                evt.preventDefault();
                this.setActive(this.getIndex(+1));
                if(this.disableOnInteraction) {
                    this.autoplay = false;
                }
                this.resetCurrentVideo();
            } else if (evt.keyCode === 37) {
                // Left Arrow
                evt.preventDefault();
                this.setActive(this.getIndex(-1));
                if(this.disableOnInteraction) {
                    this.autoplay = false;
                }
                this.resetCurrentVideo();
            }
        }
    }

    bindControl = (controlEl) => {
        this.btnStart = controlEl.querySelector('.sr-slider-start');
        this.btnStop = controlEl.querySelector('.sr-slider-stop');

        if (this.autoplay) {
            this.btnStart.style.display = 'none';
            this.btnStop.style.display = null;
        } else {
            this.btnStart.style.display = null;
            this.btnStop.style.display = 'none';
        }

        if (typeof (this.btnStart) != 'undefined') {
            this.btnStart.addEventListener('click', evt => {
                evt.preventDefault();

                this.btnStart.style.display = 'none';
                this.btnStop.style.display = null;

                this.autoplay = true;

                if(this.currentVideo !== null) {
                    const videoRestDuration = this.currentVideo.duration - this.currentVideo.currentTime;
                    this.currentVideo.play();
                    this.startProgressbarFixed(this.progressInnerEl[this.activeIndex], videoRestDuration);

                    const currentAutoplayTimeout = setTimeout(() => {
                        this.resetCurrentVideo();
                        this.setActive('next');
                    }, videoRestDuration * 1000);
                    this.autoplayTimeout.push(currentAutoplayTimeout);

                } else {
                    this.setActive(this.activeIndex);
                }
            });
        }
        if (typeof (this.btnStop) != 'undefined') {
            this.btnStop.addEventListener('click', evt => {
                evt.preventDefault();
                this.clearTimeouts();

                this.btnStart.style.display = null;
                this.btnStop.style.display = 'none';

                this.autoplay = false;

                if(this.currentVideo !== null) {
                    this.currentVideo.pause();
                    this.stopProgressbarFixed(this.progressInnerEl[this.activeIndex]);
                } else {
                    this.setProgress();
                }

            });
        }
    }

    initPagination = (paginationEl) => {
        if(this.paginationType === 'fraction') {
            let currentEl = document.createElement('span');
            currentEl.classList.add('current');
            currentEl.innerText = (this.activeIndex + 1).toString();
            this.paginationCurrentEl = currentEl;

            let totalEl = document.createElement('span');
            totalEl.classList.add('total');
            totalEl.innerText = (this.slides.length).toString();

            paginationEl.classList.add('fraction');
            paginationEl.appendChild(currentEl);
            paginationEl.appendChild(totalEl);
        } else {
            for (let i = 0; i < this.slides.length; i++) {
                let paginationBullet = document.createElement('span');

                paginationBullet.addEventListener('click', evt => {
                    this.slides.forEach(function (item) {
                        item.classList.remove('is-prev', 'is-next', 'is-next-after');
                    });
                    this.sliderEl.classList.add('is-animating');
                    this.slides[i].classList.add('is-next');
                    this.setActive(i);

                    if(this.disableOnInteraction && this.btnStart != null && this.btnStop != null) {
                        this.autoplay = false;
                        this.btnStart.style.display = null;
                        this.btnStop.style.display = 'none';
                    }
                });

                paginationEl.appendChild(paginationBullet);
                this.paginationBullets.push(paginationBullet);
            }

            paginationEl.classList.add('bullets');
        }
    }

    resetCurrentVideo = () => {
        if(this.currentVideo !== null) {
            this.currentVideo.pause();
            this.currentVideo.currentTime = 0;
            this.currentVideo = null;
        }
    };

    updatePagination = () => {
        if(this.paginationType === 'fraction') {
            this.paginationCurrentEl.innerText = (this.activeIndex + 1).toString();
        } else {
            this.paginationBullets.forEach(function (paginationBullet) {
                paginationBullet.classList.remove('active');
            });

            this.paginationBullets[this.activeIndex].classList.add('active')
        }
    }

    initProgress = () => {
        if (this.progressMultiple) {
            this.progressEl.classList.add('multiple');

            for (let i = 0; i < this.slides.length; i++) {
                let outerEl = document.createElement('span');
                let innerEl = document.createElement('span');
                outerEl.appendChild(innerEl);
                this.progressInnerEl.push(innerEl);
                this.progressEl.appendChild(outerEl);

                outerEl.addEventListener('click', evt => {
                    this.slides.forEach(function (item) {
                        item.classList.remove('is-prev', 'is-next', 'is-next-after');
                    });
                    this.sliderEl.classList.add('is-animating');
                    this.slides[i].classList.add('is-next');
                    this.setActive(i);

                    if(this.disableOnInteraction && this.btnStart != null && this.btnStop != null) {
                        this.autoplay = false;
                        this.btnStart.style.display = null;
                        this.btnStop.style.display = 'none';
                    }
                });
            }
        } else {
            let outerEl = document.createElement('span');
            let innerEl = document.createElement('span');
            outerEl.appendChild(innerEl);

            this.progressInnerEl.push(innerEl);
            this.progressEl.appendChild(outerEl);

        }

        this.progressInitialized = true;
    }

    setProgress = (currentAutoplayDuration = this.transitionDuration) => {
        if(this.progressMultiple) {
            if(this.autoplay) {
                for (let i = 0; i <= this.slides.length - 1; i++) {
                    if(i < this.activeIndex) {
                        this.setProgressbarFilling(this.progressInnerEl[i]);
                    } else if(i === this.activeIndex) {
                        this.setProgressbarFilling(this.progressInnerEl[i], currentAutoplayDuration);
                    } else {
                        this.setProgressbarEmpty(this.progressInnerEl[i]);
                    }
                }
            } else {
                for (let i = 0; i <= this.slides.length - 1; i++) {
                    if(i <= this.activeIndex) {
                        this.setProgressbarFilling(this.progressInnerEl[i]);
                    } else {
                        this.setProgressbarEmpty(this.progressInnerEl[i]);
                    }
                }
            }
        } else {
            this.setProgressbarFilling(this.progressInnerEl[0], currentAutoplayDuration);
        }
    }

    setProgressbarEmpty = (el) => {
        el.style.transitionDuration = null;
        el.style.width = '0';
    };

    setProgressbarFilling = (el, duration = 0) => {
        requestAnimationFrame(() => {
            el.style.transitionDuration = null;
            el.style.width = '0';
            requestAnimationFrame(() => {
                if(duration > 0) {
                    el.style.transitionDuration = duration + 'ms';
                }
                el.style.width = '100%';
            });
        });
    };

    stopProgressbarFixed = (el) => {
        el.style.width = el.getBoundingClientRect().width + 'px';
        el.style.transitionDuration = null;
    };

    startProgressbarFixed = (el, duration) => {
        el.style.transitionDuration = duration + 's';
        el.style.width = '100%';
    };

    initHoverEffect = () => {
        let slideImages = this.sliderContainer.querySelectorAll('.sr-slider > li svg');
        slideImages.forEach((slideImage) => {
            slideImage.addEventListener('mousemove', e => {
                this.handleHoverEffect(e);
            });
        });
    }

    handleHoverEffect = (e) => {
        const {clientX, clientY, currentTarget} = e;
        const {width, height, left: x, top: y} = currentTarget.getBoundingClientRect();

        const horizontal = (clientX - x) / width;
        const vertical = (clientY - y) / height;
        const rotateX = (this.hoverEffectThreshold / 2 - horizontal * this.hoverEffectThreshold).toFixed(2);
        const rotateY = (vertical * this.hoverEffectThreshold - this.hoverEffectThreshold / 2).toFixed(2);
        currentTarget.style.transform = `perspective(${width}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
    }

    setActive = (dir = 'next', isInit = false) => {
        if(this.slides.length === 1) {
            const videoYoutube = this.slides[this.activeIndex].classList.contains('youtube');

            if(videoYoutube) {
                this.autoplay = false;
                if (typeof this.events['videoStart'] === 'function') {
                    this.events['videoStart'](this);
                }
                return;
            }

            const video = this.slides[this.activeIndex].querySelector('video');
            if(video) {
                video.play();
            }
        }

        if(this.animating || this.shouldAnimate === false) {
            return;
        }
        this.clearTimeouts();

        if (dir === 'next') {
            this.activeIndex = this.getIndex(+1);
        } else if (dir === 'prev') {
            this.activeIndex = this.getIndex(-1);
        }

        if (typeof dir === 'number') {
            this.activeIndex = dir;
        }

        if(this.loop === false) {
            if(this.activeIndex > 0) {
                this.btnPrev.classList.remove('disabled');
            }
            if(this.activeIndex < this.slides.length - 1) {
                this.btnNext.classList.remove('disabled');
            }

            if(this.activeIndex === this.slides.length - 1) {
                this.btnNext.classList.add('disabled');
            } else if (this.activeIndex === 0) {
                this.btnPrev.classList.add('disabled');
            }
        }

        const video = this.slides[this.activeIndex].querySelector('video');

        let currentAutoplayDuration = this.autoplayDuration;

        if (this.randomAnimationClasses.length > 0) {
            const randomClass = this.randomAnimationClasses[Math.floor(Math.random() * this.randomAnimationClasses.length)];
            requestAnimationFrame(() => {
                this.slides[this.activeIndex].classList.add(randomClass);
            });
        }

        if(video) {
            if(this.videoDuration === null) {
                currentAutoplayDuration = video.duration * 1000;
            } else {
                currentAutoplayDuration = this.videoDuration;
            }

            if(isNaN(currentAutoplayDuration)) {
                video.addEventListener('loadedmetadata', evt => {
                    currentAutoplayDuration = video.duration * 1000;
                    this.startAutoplayTimeout(currentAutoplayDuration, dir, isInit);
                }, {once: true});
            } else {
                this.startAutoplayTimeout(currentAutoplayDuration, dir, isInit);
            }

        } else {
            this.startAutoplayTimeout(currentAutoplayDuration, dir, isInit);
        }

    }

    setSlideClasses = () => {
        this.slides.forEach(function (item) {
            item.classList.remove('is-prev', 'is-active', 'is-next', 'is-next-after');
        });

        this.slides[this.getIndex(-1)].classList.add('is-prev');
        this.slides[this.activeIndex].classList.add('is-active');
        this.slides[this.getIndex(+1)].classList.add('is-next');
        // this.slides[this.getIndex(+2)].classList.add('is-next-after');
    }

    startAutoplayTimeout = (currentAutoplayDuration = this.autoplayDuration, dir = 'next', isInit = false) => {
        this.animating = true;

        if(!isInit) {
            if (dir === 'next') {
                this.sliderEl.classList.add('is-animating');
            } else if (dir === 'prev') {
                this.sliderEl.classList.add('is-animating', 'reverse');
            }

            if (typeof this.events['slideChangeStart'] === 'function') {
                this.events['slideChangeStart'](this);
            }
        }

        const currentTransitionTimeout = setTimeout(() => {
            this.setSlideClasses();
            this.sliderEl.classList.remove('is-animating', 'reverse');
            this.animating = false;

            if (this.paginationCurrentEl || this.paginationBullets.length > 0) {
                this.updatePagination();
            }

            const currentVideo = this.slides[this.activeIndex].querySelector('video');
            if(currentVideo && this.autoplay) {
                currentVideo.play();
                this.currentVideo = currentVideo;

                if (typeof this.events['videoStart'] === 'function') {
                    this.events['videoStart'](this);
                }
            }

            const videoYoutube = this.slides[this.activeIndex].classList.contains('youtube');

            if(videoYoutube) {
                this.autoplay = false;
                if (typeof this.events['videoStart'] === 'function') {
                    this.events['videoStart'](this);
                }
                return;
            }

            if(this.progressInitialized) {
                this.setProgress(currentAutoplayDuration);
            }

            if(this.autoplay && ((this.loop === false && this.activeIndex < this.slides.length - 1) || this.loop === true)) {
                const currentAutoplayTimeout = setTimeout(() => {
                    if (typeof this.events['videoEnd'] === 'function') {
                        this.events['videoEnd'](this);
                    }

                    this.resetCurrentVideo();
                    this.setActive('next');

                }, currentAutoplayDuration);
                this.autoplayTimeout.push(currentAutoplayTimeout);
            }
            if(this.loop === false && this.activeIndex === this.slides.length - 1) {
                const currentAutoplayTimeout = setTimeout(() => {
                    if(!isInit) {
                        if (typeof this.events['reachEnd'] === 'function') {
                            this.events['reachEnd'](this);
                        }
                    }
                }, currentAutoplayDuration);
                this.autoplayTimeout.push(currentAutoplayTimeout);
            }

            if(!isInit) {
                if (typeof this.events['slideChangeEnd'] === 'function') {
                    this.events['slideChangeEnd'](this);
                }
            }

            if(this.autoHeight) {
                let nextSlideHeight = this.slides[this.activeIndex].getBoundingClientRect().height;
                this.sliderEl.style.height = nextSlideHeight + 'px';
            }
        }, this.transitionDuration);
        this.transitionTimeout.push(currentTransitionTimeout)
    }

    startAutoplay = () => {
        this.autoplay = true;

        if(this.btnStart != null && this.btnStop != null) {
            this.btnStart.style.display = 'none';
            this.btnStop.style.display = null;
        }

        this.setActive(this.activeIndex);
    };

    getIndex = (offset) => {
        let newIndex = this.activeIndex + offset;

        if (newIndex > this.slides.length - 1 && newIndex >= 0) {
            newIndex = newIndex - this.slides.length;
        } else if (newIndex < 0) {
            newIndex = newIndex + this.slides.length;
        }

        return newIndex;
    }

    clearTimeouts = () => {
        this.autoplayTimeout.forEach((timeout) => {
            clearTimeout(timeout);
        });
        this.autoplayTimeout = [];

        this.transitionTimeout.forEach((timeout) => {
            clearTimeout(timeout);
        });
        this.transitionTimeout = [];
    }

    destroy = () => {
        this.clearTimeouts();
        document.removeEventListener('keydown', this.handleKeyboard, false);
    }

}

window.Slider = Slider;
